<template>
  <div class="main">
    <el-row>
      <!-- 左侧卡片 -->
      <el-col :span="8">
        <div class="left">
          <div class="box">
            <div>智能 协同 高效</div>
            <div class="tips">一站式便捷的员工自助服务</div>
            <img src="@/assets/image/signin/bg2.png" />
          </div>
        </div>
      </el-col>
      <!-- 右侧表单 -->
      <el-col :span="16">
        <div class="felx-box">
          <div class="from-box">
            <div class="title">欢迎使用沙特综合管理系统</div>
            <div class="box-line">
              <div class="box-title">登录账号</div>
              <div class="input-box">
                <input
                  type="text"
                  v-model="account"
                  placeholder="请输入登录账号"
                />
                <div class="img-box">
                  <img :src="require('@/assets/image/signin/account.png')" />
                </div>
              </div>
            </div>
            <div class="box-line">
              <div class="box-title">账号密码</div>
              <div class="input-box">
                <input
                  :type="type"
                  placeholder="请输入账号密码"
                  v-model="password"
                />
                <div class="img-box">
                  <img :src="require('@/assets/image/signin/password.png')" />
                </div>
                <img class="type-img" @click="changeTypeImg" :src="typeImg" />
              </div>
            </div>
            <div class="box-line box-line2">
              <div class="box-title">验证码</div>
              <div class="box-item">
                <div class="input-box">
                  <input
                    type="text"
                    v-model="code"
                    placeholder="请输入验证码"
                  />
                  <div class="img-box">
                    <img :src="require('@/assets/image/signin/code.png')" />
                  </div>
                </div>
                <img class="yzm" @click="getCaptcha" :src="captchaImg" />
              </div>
            </div>
            <button @click="submit" class="submit">
              <i class="el-icon-refresh-right refresh" v-if="loading"></i
              >登录<span v-if="loading">中</span>
            </button>
          </div>
          <div class="bottom">
            Copyright © 2024 HRMS/SPEC/SNEI All Rights Reserved.
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getCaptcha, setLogin, getUserinfo, getMenuTree } from "@/api/user";
import Fingerprint from "@/utils/fingerprint";
export default {
  data() {
    return {
      type: "password",
      typeImg: require("@/assets/image/signin/eye-close.png"),
      loading: false,
      captchaImg: null,
      captchaId: null,
      account: null,
      password: null,
      code: null,
    };
  },
  methods: {
    //获取图形验证码
    getCaptcha() {
      getCaptcha().then((res) => {
        this.captchaImg = "data:image/png;base64," + res.response.base64;
        this.captchaId = res.response.id;
      });
    },

    changeTypeImg() {
      if (this.type === "password") {
        this.type = "text";
        this.typeImg = require("@/assets/image/signin/eye-open.png");
      } else {
        this.type = "password";
        this.typeImg = require("@/assets/image/signin/eye-close.png");
      }
    },
    //登录
    submit() {
      if (this.loading) {
        return;
      }

      if (!this.account) {
        this.$message.warning("登录账号不能为空");
        return;
      }
      if (!this.password) {
        this.$message.warning("账号密码不能为空");
        return;
      }
      if (!this.code) {
        this.$message.warning("验证码不能为空");
        return;
      }
      const data = {
        account: this.account,
        password: this.password,
        captchaId: this.captchaId,
        code: this.code,
        keyID: new Fingerprint().get(),
      };
      this.loading = true;
      setLogin(data).then((res) => {
        this.loading = false;
        if (res.status === 200) {
          getUserinfo({ id: res.response }).then((res) => {
            if (res.status === 200) {
              this.$store.commit("changeUserInfo", res.response);
              localStorage.setItem("userInfo", JSON.stringify(res.response));
              this.$router.push("/ordinary/home");
              console.log('res.response',res.response)
              return
              this.$router.push("/ordinary/home");
            }
          });
          getMenuTree({ id: res.response }).then((res) => {
            if (res.status === 200) {
              this.$store.commit("changeUserPower", res.response);
              localStorage.setItem("userPower", JSON.stringify(res.response));
            }
          });
        } else {
          this.getCaptcha();
        }
      });
    },
    handleGlobalEnter(event) {
      if (event.keyCode === 13) {
        // 处理回车事件
        this.submit();
      }
    },
  },
  created() {
    this.getCaptcha();
    document.addEventListener("keyup", this.handleGlobalEnter);
  },
  destroyed() {
    document.removeEventListener("keyup", this.handleGlobalEnter);
  },
};
</script>
<style lang="scss" scoped>
.main {
  min-width: 1000px;
  .left {
    height: 100vh;
    width: 100%;
    min-height: 700px;
    background-image: url("../assets/image/signin/bg1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .box {
      width: 74%;
      margin-left: 13%;
      text-align: center;
      font-size: 28px;
      color: #fff;
      // font-weight: bolder;
      position: relative;
      top: 13%;
      img {
        width: 100%;
      }
      .tips {
        margin-top: 23px;
        margin-bottom: 91px;
      }
    }
  }
  .felx-box {
    height: 100vh;
    width: 100%;
    min-height: 700px;
    position: relative;
    .from-box {
      width: 438px;
      position: relative;
      left: 50%;
      top: 21%;
      transform: translateX(-50%);
      text-align: center;
      .title {
        color: #333333;
        font-size: 26px;
        // font-weight: bolder;
        margin-bottom: 15px;
      }
      .box-line {
        text-align: left;
        .box-title {
          margin-top: 25px;
          margin-bottom: 15px;
          color: #333333;
          font-size: 14px;
        }
        .input-box {
          width: 100%;
          position: relative;
          input {
            border: none;
            outline: none;
            width: 100%;
            height: 48px;
            border: 1px solid #cccccc;
            border-radius: 2px;
            font-size: 14px;
            box-sizing: border-box;
            padding-left: 65px;
            padding-right: 46px;
          }
          .img-box {
            position: absolute;
            top: 1px;
            left: 1px;
            height: 46px;
            width: 46px;
            background-color: #f7f9fc;
            border-right: 1px solid #cccccc;
            img {
              width: 20px;
              height: 20px;
              position: relative;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .type-img {
            position: absolute;
            width: 22px;
            height: 22px;
            top: 12px;
            right: 12px;
            cursor: pointer;
          }
        }
      }
      .box-line2 {
        .box-item {
          display: flex;
          justify-content: space-between;
          .input-box {
            input {
              width: 285px;
            }
          }
          .yzm {
            flex-shrink: 0;
            width: 151px;
            height: 48px;
            cursor: pointer;
          }
        }
      }
      .submit {
        border: none;
        padding: none;
        width: 100%;
        height: 54px;
        background: #2687ff;
        border-radius: 4px 4px 4px 4px;
        margin-top: 29px;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
        .refresh {
          margin-right: 10px;
          -webkit-animation: rotate 1s linear infinite;
          -moz-animation: rotate 1s linear infinite;
          -o-animation: rotate 1s linear infinite;
          animation: rotate 1s linear infinite;
        }
      }
      .submit:hover {
        background: rgba(38, 135, 255, 0.8);
      }
    }
    .bottom {
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      bottom: 36px;
      color: #06052b;
      font-size: 14px;
    }
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotate {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
<style scoped>
input::-webkit-input-placeholder {
  color: #ccc;
}
/* Firefox */
input::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}
/* IE */
input:-ms-input-placeholder {
  color: #ccc;
}
input[type="password"]::-ms-reveal {
  display: none;
}

input[type="password"]::-ms-clear {
  display: none;
}

input[type="password"]::-o-clear {
  display: none;
}
</style>